/* ViewPayment.module.css */

.container {
    padding: 20px;
  }
  
  .modalTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .customModal {
    width: 800px;
  }
  
  .invoiceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logoContainer {
    width: 100px; /* Adjust the width of the logo container as needed */
  }
  
  .logo {
    width: 100%;
    height: auto;
  }
  
  .invoiceHeaderRight {
    text-align: right;
  }
  
  .customerDetails {
    margin-bottom: 20px;
  }
  
  .paymentDetails {
    margin-top: 20px;
  }
  
  /* Align data on the right */
  .invoiceHeaderRight,
  .paymentDetails {
    text-align: right;
  }
  
  /* Add any additional styles based on your design requirements */
  