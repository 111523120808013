.content-transition {
  transition: margin-left 0.2s ease-in-out;
}

/* Targets all webkit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  height: 6px;
  /* Adjusts the thickness of the horizontal scrollbar */
  background-color: #F5F5F5;
  /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  /* Color of the scroll handle */
  border-radius: 3px;
  /* Makes the corners rounded */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
  /* Color of the handle on hover */
}

/* Targets all webkit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 6px;
  /* Adjusts the thickness of the vertical scrollbar */
  background-color: #F5F5F5;
  /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  /* Color of the scroll handle */
  border-radius: 3px;
  /* Makes the corners rounded */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
  /* Color of the handle on hover */
}

/* Your existing styles */
.invoice-header {
  display: flex;
  justify-content: space-between;
}

.centered-description {
  text-align: center;
}