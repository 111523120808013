.row-flex {
    display: flex;
}

.row-flex > .ant-col {
    display: flex;
    flex-direction: column;
}

.row-flex > .ant-col > .custom-card {
    flex: 1;
    display: flex;
    flex-direction: column;
}


