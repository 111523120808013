/* RoleForm.css */
.role-form {
    max-width: 600px;
    margin: 0 auto;
}

.permission-block {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.permission-block .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.permission-block .header .select-all {
    margin-left: 15px;
}

.actions {
    display: flex;
    flex-wrap: wrap;
}

.action-item {
    width: auto;
    margin: 5px 10px 5px 0;
}