.table-1 {
  border: 2px solid black;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
}

.table-1 td {
  border: 2px solid black;
  columns: 2;
}

.table-1 th {
  border: 2px solid black;
}

.table-2 {
  border: 2px solid black;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
}

.table-2 td {
  border: 2px solid black;
  columns: 2;
}

.table-2 th {
  border: 2px solid black;
}

.table-3 {
  border: 2px solid black;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
}

.table-3 td {
  border: 2px solid black;
  font-weight: bold;

}

.table-3 th {
  border: 2px solid black;
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
  color: rgb(225, 7, 28);

}

.table-4 {
  border: 2px solid black;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
}

.table-4 td {
  border: 2px solid black;
  columns: 2;
  font-weight: bold;

}

.table-4 th {
  border: 2px solid black;
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
  color: rgb(225, 7, 28);
}

@media print {

  /* General Page Settings */
  body {
    font-family: 'Arial', sans-serif;
    color: #333;
    background: none !important;
    visibility: visible !important;
  }

  div,
  table,
  th,
  td {
    display: block !important;
    width: 100% !important;
    visibility: visible !important;
    overflow: visible !important;
    position: relative !important;
  }

  /* Removing input fields and showing their value */
  input[type="number"] {
    display: inline !important;
    visibility: visible !important;
    font-size: 14px !important;
    border: none;
    background: none;
    width: auto;
    margin: 0;
    padding: 0;
    pointer-events: none;
  }

  /* Styling the tables */
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 15px 0;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px 12px;
    text-align: left;
  }

  th {
    background-color: #f7f7f7;
    font-weight: 600;
    color: #555;
  }

  /* Alternate rows */
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  /* Total row */
  tr:last-child th,
  tr:last-child td {
    background-color: #e9ecef;
    font-weight: 700;
  }

  /* Additional Spacing */
  div {
    margin-bottom: 25px;
  }
}

@media print {
  body::before {
    content: counter(page);
    display: block;
    display: none;
  }
}

/* @media print {
  @page :first {
    display: none;
  }
} */