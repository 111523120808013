
.ant-layout-sider-trigger{
    /* background-color:#919496 !important; */
    width: 80%;
    /* color: black !important; */
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
@media only screen and (max-width: 576px) {
    /* .ant-layout-sider {
        display: none;
    } */
}
.sider-transition {
    transition: width 0.2s ease-in-out;
}
.dark-mode-switch {
    padding: 10px;
    text-align: center;
}

/* Theming */