
/* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.login-form-container {
    width: 300px;
    margin: auto;
    padding: 50px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);


}

.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
} */
.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url(../../assets/login-bg.jpg); /* Use the imported image */
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
    background-blend-mode: multiply; /* Blend mode to blend the color with the image */
}


.login-container {
    display: flex;
    width: 50%; /* You can adjust this percentage for desired width */
    background-color: #fff; /* White background to resemble pages of an open book */
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 5px; /* You can adjust this for more or less curvature */
    overflow: hidden; /* This ensures no child can overflow the rounded corners */
}

.login-sidebar, .login-form-container {
    flex: 1;
    padding: 20px;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.1); /* Inner shadow to give a bit of depth */
    height: 400px; /* Keep both divs the same height; adjust if needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-sidebar {
    background-color: #323f4b; /* Adjust this color for the left 'cover' of the book */
    color: #ffffff; /* White text color */
}

.login-form-container {
    background-color: #f9f9f9; /* Slightly off-white to resemble pages of a book */
}

.login-logo {
    width: 150px;
    margin-bottom: 20px;
    align-self: center; /* Center the logo horizontally */
}

.login-form {
    width: 100%; /* Make the form stretch to the width of its container */
}

.login-form-button {
    width: 100%;
}

@media (max-width: 768px) {
    .login-sidebar {
        display: none;
    }
    .login-form-container {
        padding: 30px 20px; /* Reduce padding for mobile but ensure there's enough space */
        box-shadow: none;  /* Optionally remove or soften shadows for a cleaner look */
    }

    .login-form {
        font-size: 16px; /* Adjust font size for mobile readability */
    }

  
    .login-form input {
        font-size: 16px; /* Larger text for inputs */
        padding: 10px;   /* More padding for comfort */
        margin-bottom: 15px; /* Space out the inputs */
        border-radius: 5px; /* Rounded corners for the inputs */
    }

    .login-form-item {
        margin-bottom: 20px; /* Spacing between form items */
    }
    .login-container{
        width: 80%;
    }
}
.login-form-forgot {
    float: right;
    margin-right: 8px; /* Add some margin for better spacing */
}

.login-form-forgot a {
    color: #1890ff; /* Link color */
}

.login-form-forgot a:hover {
    text-decoration: underline; /* Underline on hover */
}