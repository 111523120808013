/* You can add styling here as per your needs */
.container {
    padding: '1rem';
    background-color: '#f5f5f5';
}

.addButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.addButton {
    border: none;
    /* Remove border */
    color: white;
    /* White text */
    /* padding: 12px 24px; Some padding */
    cursor: pointer;
    /* Pointer/hand icon */
    border-radius: 4px;
    /* Rounded corners */
    /* font-size: 16px; Increase font size */
    width: 200px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

/* Print-specific styles */
.receiptContainer {
    display: none;

}

@media print {
    /* By default, hide everything */
    body * {
        display: none;
    }

    /* Only display the receiptContainer when printing */
    body .receiptContainer, 
    .receiptContainer *,
    .receiptContainer *:before,
    .receiptContainer *:after {
        display: block;
    }

    .receiptContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}