@media print {
    .receiptContainer {
        width: 80%;
        margin: 20px auto;
        border: 1px solid #ccc;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .logoContainer {
        text-align: center;
        margin-bottom: 20px;
    }

    .logo {
        width: 200px;
        height: 200px;
    }

    .orderDetails {
        display: flex;
        flex-wrap: wrap;
    }

    .orderDetails p {
        margin: 5px;
        flex: 1;
        /* To distribute evenly in a flex container */
    }

    .itemsTable {
        width: 100%;
        border-collapse: collapse;
    }

    .itemRow {
        /* display: table-row; */
        display: flex;
        border-bottom: 1px solid #ccc;
    }

    .itemRow td {
        padding: 10px;
    }

    .itemsHeader {
        font-weight: bold;
    }

    .totals {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .totals p {
        margin: 5px 0;
    }
}